/***News and stocks***/
.news-stocks{
	.screen-6{
		.pane-title{
			padding: 85px 0 53px 0;
		}
	} 
	.view-news-and-actions{
		.views-row{
			width: 373px;
			background: #f6f6f6;
			height: 612px;
			position: relative;
			padding: 0;
			margin: 0 30px 60px 0;
			float: left;
			img {
				width: 100%;
			}
			&:nth-child(3n+3){
				margin-right: 0;
			}
			&:nth-child(2){
				.views-field-field-image{
					img{
						border-color:#505074;
					}
				}
				.views-field-view-node{
					background: #505074;
				}
			}
			&:nth-child(3){
				.views-field-field-image{
					img{
						border-color:#75b0a8;
					}
				}
				.views-field-view-node{
					background: #75b0a8;
				}
			}
			&:nth-child(4){
				.views-field-field-image{
					img{
						border-color:#9fa4be;
					}
				}
				.views-field-view-node{
					background:#9fa4be;
				}
			}
			&:nth-child(5){
				.views-field-field-image{
					img{
						border-color:#f68e56;
					}
				}
				.views-field-view-node{
					background: #f68e56;
				}
			}
			&:nth-child(6){
				.views-field-field-image{
					img{
						border-color:#505074;
					}
				}
				.views-field-view-node{
					background: #505074;
				}
			}
			&:nth-child(7){
				.views-field-field-image{
					img{
						border-color:#75b0a8;
					}
				}
				.views-field-view-node{
					background: #75b0a8;
				}
			}
			&:nth-child(8){
				.views-field-field-image{
					img{
						border-color:#9fa4be;
					}
				}
				.views-field-view-node{
					background: #9fa4be;
				}
			}
			&:nth-child(9){
				.views-field-field-image{
					img{
						border-color:#f68e56;
					}
				}
				.views-field-view-node{
					background: #f68e56;
				}
			}
			&:nth-child(10){
				.views-field-field-image{
					img{
						border-color:#505074;
					}
				}
				.views-field-view-node{
					background: #505074;
				}
			}
			&:nth-child(11){
				.views-field-field-image{
					img{
						border-color:#75b0a8;
					}
				}
				.views-field-view-node{
					background: #75b0a8;
				}
			}
			&:nth-child(12){
				.views-field-field-image{
					img{
						border-color:#9fa4be;
					}
				}
				.views-field-view-node{
					background: #9fa4be;
				}
			}
			.views-field-title, .views-field-body, .views-field-view-node{
				margin: 0 38px;
			}
			.views-field-field-image{
				img{
					border-top:4px solid #f68e56;
				}
			}
			.views-field-title{
				font-size: 17px;
				font-weight: bold;
				height: 108px;
				padding: 30px 0;
				display: inline-block;
				a{
					color: #4f4f73;
				}
			}
			.views-field-body{
				height: 227px;
				overflow: auto;
				margin-bottom: 30px;
				color: #868686;
			}
			.views-field-view-node{
				&:hover{
					opacity: 0.85;
				}
				background: #f68e56;
				
				a{
					color: #fff;
					font-weight: bold;
					&:hover{
						text-decoration: none;
					}
				}
			}
			
		}
		.item-list{
			clear: both;
			ul{
				margin: 0 0 50px 0;
				li.pager-last,li.pager-first{
					display: none;
				}
				li.pager-previous{
					margin: 0 24px 0 0;
				}
				li.pager-next{
					margin: 0 0px 0 3px;
				}
				li.pager-previous,li.pager-next{
					a{
						border:none;
						padding: 0;
						color: #868686;
						font-weight: bold;
						height: 40px;
						line-height: 40px;
						&:hover{
							background: none;
							color: #f58d55;
							text-decoration: underline;
						}

					}
				}
				li.pager-item, li.pager-current{
					margin: 0 19px 0 0;
					&:hover{
						a{
							background: #e9e9e9;
						}
					}
					span, a{
						padding: 0;
						width: 40px;
						height: 40px;
						-moz-border-radius: 20px;
						-webkit-border-radius: 20px;
						border-radius: 20px;
						background-color: #f6f6f6;
						color: #878787;
						font-size: 14px;
						font-weight: bold;
						border:none;
						line-height: 40px;
					}

				}
				li.active{
					span{
						background: #f68e56;
						color: #fff;
					}
				}
			}
		}
	}
}

/***Inner page***/
.news-stocks-inner{
	#main-wrapper{
		padding: 80px 0 0 0;
	}
	.page-header{
		margin: 0 0 35px 0;
		padding: 0 0 0 0;
		border:none;
		color: #4f4f73;
		font-size: 25px;
		font-weight: bold;

	}
	.pane-node-field-image{
		padding: 0 0 46px 0;
		img{
			border-top:4px solid #f68e56;
			box-sizing: content-box;
			max-width: 100%;
		}
	}
	.pane-node-body{
		color: #868686;
		font-size: 14px;
		line-height: 1.5;
		h1,h2,h3,h4,h5,h6{
			color: #505074;
		}
		ul{
			margin: 0 0 20px 0;
			li{
				list-style-image: url(/sites/all/themes/uworld/assets/images/li-news-stock.png); 
				margin: 0 0 4px 0;
			}
		}
		p{
			padding: 0px 0 20px 0;
			margin: 0;
			strong{
				color: #4f4f73;
				font-size: 17px;
				font-weight: bold;
			}
		}
		blockquote{
			padding: 0 0 0px 10px;
			margin: 0 0 35px 0;
			p{
				color: #2f2f2f;
			}
		}
	}
	.pane-news-and-actions-panel-pane-3{
		.pane-title{
			padding: 45px 0 50px 0;
		}
		#views_slideshow_swiper_news_and_actions-panel_pane_3{
			margin-bottom: 18px;
		}
	}
}

@media (max-width: 767px) {
	.news-stocks-inner {
		.pane-node-field-image,
		.pane-node-body {
			padding-left: 20px;
			padding-right: 20px;
		}
	}
	.news-stocks {
		.view-news-and-actions {
			.view-content {
				margin: 0 auto;
				width: 320px;
			}
			.views-row {
				margin: 10px 0 !important;
				width: 100%;
			}
			.item-list {
				padding-top: 30px;
			}
		}
	}
}

@media (min-width: 768px) and (max-width: 1179px) {
	.news-stocks {
		.view-news-and-actions {
			.view-content {
				width: 100%;
			}
			.views-row {
				margin: 20px !important;
				width: 343px;
			}
			.item-list {
				padding-top: 30px;
			}
		}
	}
}