
/***DOCTOR***/
.doctor{
  .page-header{
    display: none;
  }
  #content{
    padding: 86px 0 0 0;
    overflow: hidden;
  }
  .pane-node-field-image{
    float: left;
    @include clearfix();
    margin: 0 28px 29px 0;
  }
  .pane-node-title,
  .pane-node-field-second-title,
  .pane-node-field-prebody
  {
   display: inline-block;
  } 
  .pane-node-body{
    clear: both;
    padding: 0 0 30px 0;
    p{
      color: #878787;
      line-height: 1.5;
      margin: 0 0 30px 0;
     strong{
      color: #4f4f73;
      font-size: 17px;
      font-weight: bold;
     }  
  }
  }
  .pane-node-field-prebody{
    max-width: 422px;
    blockquote{
      padding: 0 0 0px 10px;
      margin: 0 0 35px 0;
      p{
        color: #2f2f2f;
      }
    }
    p{
      line-height: 1.5;
      color: #878787;
      margin: 0;
    }
  }
  .pane-node-title{
    margin: -10px 0 16px 0;
    .pane-content{
      color: #4F4F76;
      font-family: "Open Sans", sans-serif;
      font-size: 25px;
      font-weight: bold;
    }
  }
  .pane-node-field-second-title{
    color: #fff;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: bold;
  width: 249px;
  height: 36px;
  -moz-border-radius: 18px;
  -webkit-border-radius: 18px;
  border-radius: 18px;
  background-color: #4EB1A9;
  text-align: center;
  line-height: 36px;
  margin: 0 0 31px 0;
  }

  .pane-node-field-slideshow{
    background: #f6f6f6;
    clear: both;
    padding: 69px 0 66px 0;
    position: relative;
    .field-name-field-slideshow{
      width: 1110px;
      margin: 0 auto;
    }
    &:before{
      content: "";
      position: absolute;
      width: 300%;
      height: 100%;
      left: -300%;
      top: 0;
      background: #f6f6f6;
    }
    &:after{
      content: "";
      position: absolute;
      width: 300%;
      height: 100%;
      right: -300%;
      top: 0;
      background: #f6f6f6;
    }
    .owl-wrapper-outer{
    
    }
    .owl-item{
      & > div{
        padding: 6px 12px;
        background: #fff;
        border: 1px solid #ebebeb;
        width: 248px;
        height: 356px;
      }
    }
    .owl-prev,.owl-next{
      margin: 0!important;
      padding: 0!important;
      width: 26px;
      height: 44px;
      position: relative;
      background: url(/sites/all/themes/uworld/assets/images/sprite-slider-doc.png) no-repeat 0px 0px!important;
      text-indent: -9999px;
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      z-index: 2;
    }
    .owl-prev{
      left: -40px;
    }
    .owl-next{
      background-position: -26px 0px!important;
      right: -40px;
    }
  }
  .screen-4{
    position: relative;
    &:before{
      content: "";
      position: absolute;
      width: 300%;
      height: 100%;
      left: -300%;
      top: 0;
      background: #9fa4be;
    }
    &:after{
      content: "";
      position: absolute;
      width: 300%;
      height: 100%;
      right: -300%;
      top: 0;
      background: #9fa4be;
    }
    background: #9fa4be;
    .form-actions{
      .btn{
        width: 268px;
        height: 65px;
      }
      .form-submit{
        background: #f68e56;
      }
    } 
  }
  #footer {
    margin: 0px 0;
  }
}


@media (max-width: 767px) {
  .doctor .screen-4 .form-actions .btn {
    height: 50px;
    width: 100%;
  }
  .doctor {
    #panel-bootstrap-region-center {
      padding: 0 20px;
    }
    .pane-node-field-slideshow {
      .field-name-field-slideshow {
        margin: 0 auto;
        width: 210px;
      }
      .owl-item {
        padding: 0 13px;
        width: 210px !important;
        img {
          height: auto;
          width: 100%;
        }
        > div {
          height: auto;
          width: 184px;
        }
      }
    }
    .pane-node-field-image {
      margin: 0 0 39px 0;
      width: 100%;
      img {
        height: auto;
        width: 100%;
      }
    }
  }
}




@media (min-width: 768px) and (max-width: 1179px) {
  .doctor {
    #panel-bootstrap-region-center {
      padding: 0 20px;
    }
    .pane-node-field-slideshow {
      .field-name-field-slideshow {
        margin: 0 auto;
        width: 630px;
      }
      .owl-item {
        padding: 0 13px;
        width: 210px !important;
        img {
          height: auto;
          width: 100%;
        }
        > div {
          height: auto;
          width: 184px;
        }
      }
    }
    .pane-node-field-image {
      margin: 0 0 39px 0;
      width: 100%;
      img {
        height: auto;
        width: 100%;
      }
    }
  }
}









