.service-top-wrapp{
  position: relative;
    // position: relative;
  min-height: 300px;
  background-color: #505074;
  .pane-node-title,
  .pane-node-body{
    @include make-container();
    &>.pane-content{
      position: absolute;
    }
  }
  .pane-node-title > .pane-content{
    top: calc(50% - 50px);
    color: #fff; // text color
    font-family: $ChunkFive;
    font-size: 55px;
  }
  .pane-node-body > .pane-content{
    bottom: 0;
    & > div{
      width: 60%;
      padding: 30px;
      color: #fff; 
      background: rgba(79,79,115,0.82);

    }
  }
  .field-name-field-service-image img{
    object-fit: cover;
    width: 100%;
  }
  .pane-node-field-service-image{

  }
}

/* Перебить заливку формы запишитесь на прием*/
.beruza{
  background: #75b0a8;
  .form-actions{
    .webform-submit{
      background: #f68e56;
    }
  }
}

/*
.method-halse-block{
  background: url(../images/bg-service-method-halse-block.png) no-repeat center center;
  background-size: cover;
  padding-bottom: 70px;
  .pane-title{
    @include style-pane-title-blue;
    color: #fff;
  }
  .pane-content{
    @include make-container();
    .field-name-field-programm-hals > .field-items,
    .field-name-field-method-hals > .field-items{
      & > .field-item{
        display: inline-block;
        float: left;
        width: 322px;
        height: 322px;
        background: #eb8d5d;
        margin-right: 100px;
        position: relative;
        color: #fff;
        text-align: center;
        @include border-radius(166px);
        @include clearfix;
        &:after{
          content: ". . .";
          position: absolute; 
          font-weight: bold;
          font-size: 30px;
          right: -72px;
          color: #fff;
          top: calc(50% - (30px / 2));
        }
        &:nth-child(2){
          background: #58597b;
        }
        &:last-child{
          background: #76aaa5;
          margin-right: 0px;
          padding-right: 0px;
          &:after{
            content: "";
          }
        }

        .field-name-field-title{
          font-family: $normal;
          font-size: 17px;
          font-weight: bold;
          width: 80%;
          margin: 76px auto 20px;
        }
        .field-name-field-body{
          width: 90%;
          margin: 0 auto;
          font-size: 14px;
        }
      }
    } 
  }
}
.programm-service{
  @extend .method-halse-block;
  background: #f6f6f6;
  padding-bottom: 60px;
  .pane-title{
    color: #4f4f73;
    padding-bottom: 30px;
  }
  .pane-content{
    .field-name-field-programm-hals > .field-items{
      & > .field-item{
        @include border-radius(0px);
        background: transparent;
        color: #4f4f73;
        width: 322px;
        height: auto;
        
        .field-name-field-price{
          @extend .btn;
          @extend .read-more;
          background: #f68e56;
          color: #fff;
          font-size: 17px;
          font-weight: bold;
          height: 40px;
          line-height: 19px;
          width: auto;
          margin-top: 30px;
        }

        .field-name-field-title{
          font-family: $ChunkFive;
          font-weight: normal;
          margin: 25px auto 20px;
        }
        .field-name-field-image{
          position: relative;
        }
        .field-name-field-image:after{
          content: "1";
          font-size: 36px;
          color: #fff;
          font-family: $ChunkFive;
          background: #f68e56;
          display: inline-block;
          width: 64px;
          height: 64px;
          line-height: 64px;
          left: 10px;
          bottom: 10px;
          position: absolute;
          @include border-radius(32px);
        }
        .field-name-field-body{
          min-height: 76px;
        }
        &:nth-child(2){
          background: transparent;
          .field-name-field-image:after{
            content: "2";
            background: #505074;
          }
          .field-name-field-price{
            background: #505074;
          }
        }
        &:nth-child(3){
          .field-name-field-image:after{
            content: "3";
            background: #75b0a8;
          }
        }
        &:last-child{
          background: transparent;
          .field-name-field-price{
            background: #75b0a8;
          }
        }
      }
    }
  }
}

*/


@media (max-width: 767px) {
  .service-top-wrapp {
    .pane-node-title {
      >.pane-content {
        top: 10px;
        font-size: 30px;
        padding: 0 20px;
      }
    }
  }
  .list-disease{
    &>div{
      // @include make-container();
      position: relative;
      &>div{
        position: absolute;
        bottom: 0;
        width: 100%;
        background: rgba(117,175,167, .75);
        padding: 20px;
        a{
          color:#fff;
          @include border-radius(12px);
          padding: 6px 0px 6px 60px;
          display: inline-block;
          width: 100%;
          background: url("../images/list-icon.png") #8fcec5 no-repeat 18px center;
          margin-left: 0px;
        }
        .field-items{
          .field-item{
            @include clearfix;
            float: left;
            width: 100%;
            margin-top: 10px;
            &:nth-child(1){
              margin-top: 0px;
            }
          }
        }
      }
    }
  }
}



@media (min-width: 768px) and (max-width: 1179px) {
  .list-disease{
    &>div{
      // @include make-container();
      position: relative;
      &>div{
        position: absolute;
        bottom: 0;
        width: 100%;
        background: rgba(117,175,167, .75);
        padding: 30px;
        a{
          color:#fff;
          @include border-radius(12px);
          padding: 6px 0px 6px 60px;
          display: inline-block;
          width: 100%;
          background: url("../images/list-icon.png") #8fcec5 no-repeat 18px center;
          a:nth-child(2n){
            margin-left: 0px;
          }
        }
        .field-items{
          .field-item{
            @include clearfix;
            float: left;
            width: calc(100% / 2 - 12px);
            margin-right: 6px;
            margin-left: 6px;
            margin-top: 10px;
            &:nth-child(1),
            &:nth-child(2){
              margin-top: 0px;
            }
            &:nth-child(2n-1){
              margin-right: 12px;
              margin-left: 0px;
            }
            &:nth-child(2n){
              margin-right: 0px;
              margin-left: 12px;
            }
          }
        }
      }
    }
  }
}


@media (min-width: 1180px) {
  .list-disease{
    &>div{
      // @include make-container();
      position: relative;
      &>div{
        position: absolute;
        bottom: 0;
        width: 100%;
        background: rgba(117,175,167, .75);
        padding: 30px;
        a{
          color:#fff;
          @include border-radius(12px);
          padding: 6px 0px 6px 60px;
          display: inline-block;
          width: 100%;
          background: url("../images/list-icon.png") #8fcec5 no-repeat 18px center;
          a:nth-child(3n){
            margin-left: 0px;
          }
        }
        .field-items{
          .field-item{
            @include clearfix;
            float: left;
            width: calc(100% / 3 - 12px);
            margin-right: 6px;
            margin-left: 6px;
            margin-top: 10px;
            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3){
              margin-top: 0px;
            }
            &:nth-child(3n-2){
              margin-right: 12px;
              margin-left: 0px;
            }
            &:nth-child(3n){
              margin-right: 0px;
              margin-left: 12px;
            }
          }
        }
      }
    }
  }
}




