// Styles for Menus and Navs
// -----------------------------------------------------------------------------

ul.main-menu{
  position: relative;
  & > li {
    position: static;
    padding-bottom: 20px;
    a{
      color: #fff;
      font-size: 13px;
      font-weight: bold;
    }
    &.open{
      &.dropdown{
        & > a{
          position: relative;
        }
        & > a:before{
          content: "\f0d8";
          font-family: FontAwesome;
          position: absolute;
          bottom: -27px;
          left: calc(50% - 10px);
          font-size: 19px;
          color: $navbar-default-link-hover-color;
        }
      }
    }
    ul.dropdown-menu{
      position: absolute;
      left: 0;
      top: 60px;
      background: #454565;
      border-radius: 0;
      width: 100%;
      border-top: 4px solid $navbar-default-link-hover-color;
      padding: 10px 70px 30px;
      & > li {
        padding-top: 20px;
        float: left;
        width: 50%;
        a {
          padding-left: 30px; 
          background: url(../images/menu-icon.png) no-repeat left 5px;
          &:hover {
            color: #76aaa5;
            background-color: transparent;
          }
        }
      }
    }
  } 
}

#main-menu {
  .caret {
    display: none;
  }
  > li {
    display: inline-block;
    float: none;
    > a {
      padding: 10px 20px;
      &:focus,
      &:hover {
        background: none;
      }
    }
  }
  li {
    &.open {
      > a {
        background: none;
      }
    }
  }
}

@media (max-width: 1179px) {
  #main-menu {
    margin: 0;
    width: 100%;
    a {
      text-align: center;
      white-space: normal;
    }
    > li {
      width: 100%;
      > a {

      }
      ul.dropdown-menu {
        left: 0;
        padding: 0;
        position: relative;
        top: 0;
        width: 100%;
        li {
          width: 100%;
          a {
            background: none;
            padding: 5px 15px 5px 15px;
          }
        }
      }
    }
  }
}