/***Otzivi***/
.otzivi{
  #content{
    overflow: hidden;
  }
  .pane-title{
    color: #4f4f73;
    font-size: 25px;
    font-weight: bold;
    text-align: center;
    padding: 79px 0 12px 0;
  }
  .view-what-thinks{
    .views-row{
      &:nth-child(3n+1){
        .commen{
          background: url("../images/comment-orang.png") no-repeat center center;
        }
        .name-old{
          color: #f58d55;
        }
      }
      &:nth-child(3n+2){
        .commen{
          background: url("../images/comment.png") no-repeat center center;
        }
        .name-old{
          color: #505073;;
        }
      }
      &:nth-child(3n+3){
        .commen{
          background: url("../images/comment-beruza.png") no-repeat center center;
        }
        .name-old{
          color: #75afa7;
        }
      }
      position: relative;
      padding: 40px 0 27px 0;
      .views-field-nothing {
        max-width: 364px;
          margin-bottom: 22px;
        .commen {
            width: 53px;
            height: 64px;
            display: inline-block;
            i{
              display: inline-block;
              position: relative;
              left: 21px;
              top: 16px;
              color: #fff;
            }
        }
        .name-old {
            font-size: 14px;
            font-weight: bold;
            display: inline-block;
            width: calc(100% - 70px);
            float: right;
            padding-top: 15px;
        }
      }   
      .views-field-body{
        color: #2f2f2f;
      }
    }
    .views-row:nth-child(2n+2){
      background: #f6f6f6;
      &:before{
        content: "";
        position: absolute;
        width: 300%;
        height: 100%;
        left: -300%;
        top: 0;
        background: #f6f6f6;
      }
      &:after{
        content: "";
        position: absolute;
        width: 300%;
        height: 100%;
        right: -300%;
        top: 0;
        background: #f6f6f6;
      }
    }
    .item-list{
      clear: both;
      ul{
        margin: 40px 0 122px 0;
        li.pager-last,li.pager-first{
          display: none;
        }
        li.pager-previous{
          margin: 0 24px 0 0;
        }
        li.pager-next{
          margin: 0 0px 0 3px;
        }
        li.pager-previous,li.pager-next{
          a{
            border:none;
            padding: 0;
            color: #868686;
            font-weight: bold;
            height: 40px;
            line-height: 40px;
            &:hover{
              background: none;
              color: #f58d55;
              text-decoration: underline;
            }

          }
        }
        li.pager-item, li.pager-current{
          margin: 0 19px 0 0;
          &:hover{
            a{
              background: #e9e9e9;
            }
          }
          span, a{
            padding: 0;
            width: 40px;
            height: 40px;
            -moz-border-radius: 20px;
            -webkit-border-radius: 20px;
            border-radius: 20px;
            background-color: #f6f6f6;
            color: #878787;
            font-size: 14px;
            font-weight: bold;
            border:none;
            line-height: 40px;
          }

        }
        li.active{
          span{
            background: #f68e56;
            color: #fff;
          }
        }
      }
    }
  }
}



@media (max-width: 767px) {
  .otzivi {
    .view-what-thinks {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}


@media (min-width: 768px) and (max-width: 1179px) {
  .otzivi {
    .view-what-thinks {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}








