// Page Layout
// -----------------------------------------------------------------------------
.page-page-not-found {
  .page-header {
    border: none;
    margin: 0;
    h1 {
      color: #505074;
      font-family: 'Open Sans', sans-serif;
      font-size: 72px;
      font-weight: 700;
      margin-top: 90px;
      text-align: center;
    }
  }
  .pane-404 {
    .pane-content {
      color: #303030;
      font-size: 18px;
      padding-bottom: 90px;
      .btn {
        background-color: #75b0a8;
        border-color: #64968f;
        color: #fff;
      }
    }
  }
}

//webform done page
.page-node-done {
  .webform-confirmation {
    color: #303030;
    font-size: 18px;
    left: 0;
    max-width: 768px;
    position: absolute;
    transform: translate(0, -50%);
    top: 50%;
    width: 100%;
    h2 {
      color: #505074;
      font-family: 'Open Sans', sans-serif;
      font-size: 36px;
      font-weight: 700;
    }
    .btn {
      background-color: #75b0a8;
      border-color: #64968f;
      color: #fff;
    }
  }
  .pane-pm-existing-pages {
    background: url(../images/webform_confirm_bg.jpg) left center no-repeat;
    background-size: cover;
    height: 450px;
    width: 100%;
    .links {
      display: none;
    }
    .container {
      position: relative;
      height: 450px;
    }
  }
}

#logo {
  position: relative;
  top: 30px;
  z-index: 10;
}
#header{
  background-color: #4f4f73; // layer fill content
}
.float-right{
  float: right;
}
.navbar-default{
  background-color: transparent;
  border-color: transparent;
}
.pane-title{
  margin: 0;
}
.btn{
  width: 201px;
  height: 50px;
  border-radius: 0;
  line-height: 36px;
  border: 0;
  border-bottom: 4px solid rgba(0,0,0, 0.15);
}
.btn.read-more{
  background: rgba(255,255,255, 0.8);
  font-size: 12px;
  line-height: 10px;
  height: 31px;
  padding: 9px 39px;
  border-radius: 31px;
  border: none;
}
.btn-info{
  background-color: #9fa3bd; // layer fill content
  // @include box-shadow(inset 0 -4px 0 rgba(0,0,0,.15)); // inner shadow  
  // border-bottom: 15px solid rgba(159,164,190,.15);
  &:hover{
    background-color: lighten(#9fa3bd, 5%); // layer fill content
    border-color: #9fa3bd;
  }
}
.btn-warning{
  background-color: #f58d55; // layer fill content
  // @include box-shadow(inset 0 -4px 0 rgba(0,0,0,.15)); // inner shadow
  &:hover{
    background-color: lighten(#f58d55, 5%); // layer fill content
  }
}
.top-street{
  color: #9fa3bd; // text color
  font-family: $normal;
  font-size: 13px;
  padding: 0 25px;
}
.top-phone{
  color: #fff; // text color
  font-family: $normal;
  font-size: 25px;
  font-weight: bold;
  padding-left: 28px;
  // display: inline-block;
  background: url("../images/phone.png") no-repeat left center;
}
.top-street,
.top-phone{
  line-height: 25px;
  vertical-align: bottom;
}

.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .active > a:focus{
  background-color: transparent;
  // color: #fff; // text color
  font-family: $normal;
  font-size: 13px;
  font-weight: bold;
}
.navbar{
  margin-bottom: 0;
}



.box-shadow-round{
  position: relative;
  // width: 60%;
  background: #ddd;
  -moz-border-radius: 4px;
  border-radius: 4px;
  // padding: 2em 1.5em;
  color: rgba(0,0,0, .8);
  text-shadow: 0 1px 0 #fff;
  // line-height: 1.5;
  // margin: 60px auto;
  z-index: 1;
  position: relative;
  &:before,
  &:after {
    z-index: -1;
    position: absolute;
    content: "";
    bottom: 10px;
    left: -60px;
    width: 50%;
    top: 80%;
    max-width: 370px;
    background: rgba(0, 0, 0, 0.7);
    -webkit-box-shadow: 0 15px 35px rgba(0,0,0, 0.7);
    -moz-box-shadow: 0 15px 35px rgba(0, 0, 0, 0.7);
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.7);
    -webkit-transform: rotate(-3deg);
    -moz-transform: rotate(-3deg);
    -o-transform: rotate(-3deg);
    -ms-transform: rotate(-3deg);
    transform: rotate(-3deg);
  }
  &:after {
    -webkit-transform: rotate(3deg);
    -moz-transform: rotate(3deg);
    -o-transform: rotate(3deg);
    -ms-transform: rotate(3deg);
    transform: rotate(3deg);
    right: 4px;
    left: auto;
  }
}


.screen-1 {
  background: url("../images/front-screen-1.png") no-repeat center center;
  background-size: cover;
  color: #fff;
  height: 570px;
  .pane-title{
    @include make-container();
    font-family: $ChunkFive;
    font-size: 55px;
    margin-top: 60px;
  }
  
  .pane-content {
    @include make-container();
    font-family: $ChunkFive;
    //padding-top: 60px;
    font-size: 42px;
    // @extend .box-shadow-round;
  }
}

.screen-2{
  margin-top: -164px;
  .view-content{
    @include clearfix();
    .views-row{
      float: left;
      position: relative;
      width: 290px;
      height: 200px;
      &>div{
        position: absolute;
        left: 35px;
      }
      .views-field-field-image{
        left: 0px;
      }
      .views-field-title{
        top: 65px;
        font-size: 14px;
        font-weight: bold;
        i{
          position: absolute;
          top: -35px;
        }
      }
      .views-field-view-node{
        position: absolute;
        bottom: 30px;

      }
    }
  }

}
blockquote{
  color: #303030;
  border-left: 4px solid #f68e55;
  padding: 0 20px 10px;
  color: #2f2f2f; // text color
  font-size: 14px;  
}
.screen-3, .pane-text-insert{
  margin-top: 60px;
  color: #333; // text color
  font-size: 14px;
  &.about{
    color: #878787;
  }
  b,strong{
    color: #505074;
  }
}

.pane-text-insert{
  margin-bottom: 50px;
}

.screen-4{
  background: #505074;
  .pane-title{
    text-align: center;
    color: #fff; // text color
    font-size: 21px;
    font-weight: bold;    
    padding: 70px 0 50px 0;
  }
  .pane-content{
    @include make-container();
    form{
      div{
        &>div:nth-child(1),
        &>div:nth-child(2),
        &>div:nth-child(3)
        {
          float: left;
          width: calc(100% / 3);
          display: inline-block;
          input{
            background: #737390;
            color: #fff; // text color
            font-family: $normal;
            font-size: 13px;
            padding-left: 20px;
            // @include opacity(.2); // layer alpha
            @include border-radius(19px); // border radius
            @include background-clip(padding-box); // prevents bg color from leaking outside the border
            background-color: rgba(255,255,255,0.2); 
            border-color: rgba(255,255,255,0.2);
            &::-webkit-input-placeholder {color:#fff;}
            &::-moz-placeholder          {color:#fff;}/* Firefox 19+ */
            &:-moz-placeholder           {color:#fff;}/* Firefox 18- */
            &:-ms-input-placeholder      {color:#fff;}

          }
        }
        &>div:nth-child(1){
          padding: 0 20px 0 0;
        }
        &>div:nth-child(2){
          padding: 0 10px;
        }
        &>div:nth-child(3){
          padding: 0 0 0 20px;
        }                
      }
    }
  }
  .form-actions{
    text-align: center;
    padding: 95px 0 70px 0;
    .form-submit{
      background: #75b0a8;
      font-weight: bold;
      &:hover{
        border-bottom: 4px solid rgba(0,0,0, 0.15);
        opacity: 0.85;
      }
    }
  }
}


.screen-5{
  background: #f6f6f6;
  padding-bottom: 30px;

  .pane-content{
    @include make-container();
  }
  .pane-title{
    @include style-pane-title-blue;
    padding-bottom: 30px;
    padding-top: 30px;
  }
  .views-field-nothing{
    margin-bottom: 30px;
  }
  .commen{
    background: url("../images/comment.png") no-repeat center center;
    width: 53px;
    height: 64px;
    display: inline-block;
    i{
      display: inline-block;
      position: relative;
      left:21px;
      top: 16px;
      color: #fff;
    }
  }
  .name-old{
    color: #4f4f73; // text color
    font-size: 14px;
    font-weight: bold;
    display: inline-block;
    width: calc(100% - 70px);
    float: right;
    padding-top: 10px;
  }
  .views-field-body{
    color: #2f2f2f; // text color
  }
  ul {
    li {
      width: 390px;
    }
    li:nth-child(1) {
      padding: 0 30px 0 0;
      .commen{
        background: url("../images/comment-orang.png") no-repeat center center;
      }
      .name-old{
        color: #f68e56;
      }
    }
    li:nth-child(2) {
      padding: 0 30px;
      border-right: 1px solid #c8c8cc;
      border-left: 1px solid #c8c8cc;
    }
    li:nth-child(3) {
      padding: 0 0 0 30px;
      .commen{
        background: url("../images/comment-beruza.png") no-repeat center center;
      }
      .name-old{
        color: #75b0a8;
      }      
    }
  }
  .view-content {
    position: relative;
  }
  .views-slideshow-controls-bottom {
    display: none;
  }
}
.screen-6{
  .pane-title{
    @include style-pane-title-blue;
  }
  padding-bottom: 70px;
}

.news{
  .swiper-slide {
    padding: 0 10px;
    width: 393px;
    img {
      width: 100%;
    }
  }
  .views-slideshow-controls-bottom {
    display: none;
  }
  ul{
    li{
      .read-more-news{
        a{
          color: #fff;
          font-weight: bold;
        }
      }
      .views-field-title{
        font-size: 17px;
        font-weight: bold;
        height: 108px;
        padding: 30px 0;
        display: inline-block;
        a{
          color: #4f4f73; // text color
        }
      }
      &>div{
        width: 373px;
        background: #f6f6f6;
        height: 612px;
        position: relative;
        .views-field-title,
        .views-field-body,
        .views-field-view-node{
          margin: 0 38px; 
        }
        .views-field-body{
          height: 227px;
          overflow: auto;
          margin-bottom: 30px;
        }
        .views-field-view-node{
          a:hover{
            text-decoration: none;
          }
          &:hover{
            opacity: 0.85;
          }
        }
      }
    }
    li:nth-child(3n-2){
      @include colorNews(#f68e56);
    }
    li:nth-child(3n-1){
      @include colorNews(#505074);
    }
    li:nth-child(3n){
      @include colorNews(#75b0a8);
    }
  }
}


.video-block{
  position: relative;
  overflow: hidden;
  .pane-title,
  h2{
    position: absolute;
    top: 150px;
    width: 100%;
    text-align: center;
    color: #fff;
    font-family: $ChunkFive;
    font-size: 64px;
    text-transform: uppercase;
    a{
      font-size: 64px;
      color: #fff;
      &:hover{
        text-decoration: none;
      }
    }
  }
  .pane-content{
    .field-name-field-second-title,
    .field-type-text-with-summary,
    .field-name-field-play-video-icon{
      // @include make-container();
      &>div{
        position: absolute;
        color: #fff;
        width: 100%;
        text-align: center;
      }
    }
    .field-name-field-second-title>div{
      top: 250px;
      font-size: 32px;
      font-family: $ChunkFive;
    }
    .field-type-text-with-summary > div {
      color: #4f4f73;
      top: 310px;
      font-size: 14px;
      font-weight: bold;
    }
    .field-name-field-play-video-icon > div{
      top: 370px;
      cursor: pointer;
      &:hover{
        opacity: .85;
      }
    }
    .field-name-field-id-video{
      display: none;
    }
    .field-name-field-bg-video img{
      object-fit: cover;
      width: 100%;
    }
  }
  .pane-content{

  }
}

.video-block{
  @extend .box-shadow-round;
}


#gmap_canvas{
  height: 420px;
}



footer{
  background: #505074;
}
.copyright {
  color: #fff
}
.view-id-service_front{
  .view-content{
    .views-row{
      img {
        display: block;
        width: 100%;
      }
      .icon-front{
        width: 32px;
        height: 19px;
        background: url(../images/bg_front_icon.png) no-repeat 0 0;
      }
      a{
        font-size: 14px;
        color: #998373;
        &:hover{
          text-decoration: none;
        }
      }
      .views-field-title{
        width: 150px;
        a{
          font-weight: bold;
        }
      }
      &:nth-child(2){
        .icon-front{
          background-position: -32px 0px;  
        }
        a{
          color: #59958d;
        }
      }
      &:nth-child(3){
        .icon-front{
          background-position: -64px 0px;  
        }
        a{
          color: #f7a071;
        }
      }
      &:nth-child(4){
        .icon-front{
          background-position: -96px 0px;  
        }
        a{
          color: #4b739f;
        }
      }
      &:nth-child(5){
        .icon-front{
          background-position: -0px -24px;  
        }
        a{
          color: #cc797d;
        }
      }
      &:nth-child(6){
        .icon-front{
          background-position: -32px -24px;  
        }
        a{
          color: #51657f;
        }
      }
      &:nth-child(7){
        .icon-front{
          background-position: -64px -24px;  
        }
        a{
          color: #52a59f;
        }
      }
      &:nth-child(8){
        .icon-front{
          background-position: -96px -24px;  
        }
        a {
          color: #ae9486;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .news {
    .view-content {
      margin: 0 auto;
      width: 320px;
    }
    .swiper-slide {
      padding: 0;
      width: 320px;
      > div {
        width: 320px;
      }
    }
    .views-slideshow-controls-text-pause {
      display: none;
    }
    .views-slideshow-controls-bottom {
      display: block;
      padding-top: 30px;
      text-align: center;
    }
    .views-slideshow-controls-text-previous {
      background: url(../images/sprite-slider-doc.png) no-repeat 0px 0px;
      display: inline-block;
      font-size: 0;
      height: 44px;
      margin: 0 10px;
      text-indent: -99999px;
      vertical-align: middle;
      width: 26px;
    }
    .views-slideshow-controls-text-next {
      background: url(../images/sprite-slider-doc.png) no-repeat -26px 0px;
      display: inline-block;
      font-size: 0;
      height: 44px;
      margin: 0 10px;
      vertical-align: middle;
      width: 26px;
    }
  }
  .pane-text-insert {
    padding-left: 20px;
    padding-right: 20px;
  }
  .screen-1 {
    .pane-content,
    .pane-title {
      font-size: 35px;
      line-height: normal;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  .view-id-service_front {
    overflow: hidden;
    .view-content {
      .views-row {
        width: 100%;
        .views-field-field-image {
          width: 100%;
          img {
            height: auto;
          }
        }
      }
    }
  }
  .video-block{
    position: relative;
    .pane-title,
    h2 {
      font-size: 40px;
      a {
        font-size: 40px;
      }
    }
    .pane-content {
      .field-name-field-second-title>div {
        font-size: 28px;
      }
    }
  }
  .screen-5 {
    padding-left: 20px;
    padding-right: 20px;
    .view-content {
      margin: 0 auto;
      width: 280px;
      .skin-default {
        overflow: hidden;
      }
    }
    .views-slideshow-controls-text-pause {
      display: none;
    }
    .views-slideshow-controls-bottom {
      display: block;
      padding-top: 30px;
      text-align: center;
    }
    .views-slideshow-controls-text-previous {
      background: url(../images/sprite-slider-doc.png) no-repeat 0px 0px;
      display: inline-block;
      font-size: 0;
      height: 44px;
      margin: 0 10px;
      text-indent: -99999px;
      vertical-align: middle;
      width: 26px;
    }
    .views-slideshow-controls-text-next {
      background: url(../images/sprite-slider-doc.png) no-repeat -26px 0px;
      display: inline-block;
      font-size: 0;
      height: 44px;
      margin: 0 10px;
      vertical-align: middle;
      width: 26px;
    }
    ul {
      li {
        border: none !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
        width: 280px;
      }
    }
  }
  .screen-4 {
    .pane-content {
      padding-left: 20px;
      padding-right: 20px;
      form {
        div {
          &>div:nth-child(1),
          &>div:nth-child(2),
          &>div:nth-child(3) {
            padding: 0;
            width: 100% !important;
          }
          input[type=text],
          input[type=email] {
            width: 100%;
          }
        }
      }
    }
  }
}


@media (min-width: 768px) and (max-width: 1179px) {
  #logo {
    left: 20px;
  }
  .news {
    .skin-default {
      position: relative;
    }
    .view-content {
      margin: 0 auto;
      width: 680px;
    }
    .swiper-slide {
      padding: 0 10px;
      width: 340px;
      > div {
        width: 320px;
      }
    }
    .views-slideshow-controls-bottom {
      display: block;
    }
    .views-slideshow-controls-text-pause {
      display: none;
    }
    .views-slideshow-controls-text-previous {
      background: url(../images/sprite-slider-doc.png) no-repeat 0px 0px;
      font-size: 0;
      height: 44px;
      left: -30px;
      position: absolute;
      text-indent: -99999px;
      top: 50%;
      transform: translate(0, -50%);
      width: 26px;
    }
    .views-slideshow-controls-text-next {
      background: url(../images/sprite-slider-doc.png) no-repeat -26px 0px;
      font-size: 0;
      height: 44px;
      position: absolute;
      right: -30px;
      text-indent: -99999px;
      top: 50%;
      transform: translate(0, -50%);
      width: 26px;
    }
  }
  .view-id-service_front{
    overflow: hidden;
    .view-content {
      .views-row {
        width: 50%;
        .views-field-field-image {
          width: 100%;
          img {
            height: auto;
          }
        }
      }
    }
  }
  .video-block{
    position: relative;
    .pane-title,
    h2 {
      font-size: 40px;
      a {
        font-size: 40px;
      }
    }
  }
  .screen-5 {
    .view-content {
      margin: 0 auto;
      width: 650px;
      .skin-default {
        overflow: hidden;
      }
    }
    .views-slideshow-controls-bottom {
      display: block;
    }
    .views-slideshow-controls-text-pause {
      display: none;
    }
    .views-slideshow-controls-text-previous {
      background: url(../images/sprite-slider-doc.png) no-repeat 0px 0px;
      font-size: 0;
      height: 44px;
      left: -45px;
      position: absolute;
      text-indent: -99999px;
      top: 50%;
      transform: translate(0, -50%);
      width: 26px;
    }
    .views-slideshow-controls-text-next {
      background: url(../images/sprite-slider-doc.png) no-repeat -26px 0px;
      font-size: 0;
      height: 44px;
      position: absolute;
      right: -45px;
      text-indent: -99999px;
      top: 50%;
      transform: translate(0, -50%);
      width: 26px;
    }
    ul {
      li {
        width: 325px;
      }
    }
  }
  .screen-4 {
    .pane-content {
      form {
        div {
          &>div:nth-child(1) {
            padding-left: 20px;
            width: 50% !important;
            input[type=text],
            input[type=email] {
              float: left;
            }
          }
          &>div:nth-child(2) {
            padding-right: 20px;
            width: 50% !important;
            input[type=text],
            input[type=email] {
              float: right;
            }
          }
          &>div:nth-child(3) {
            width: 100% !important;
            input[type=text],
            input[type=email] {
              margin: 0 auto;
            }
          }
          input[type=text],
          input[type=email] {
            width: 300px;
          }
        }
      }
    }
  }
}


.col-xs-1-5,
.col-sm-1-5,
.col-md-1-5,
.col-lg-1-5 {
  position: relative;
  min-height: 1px;
  padding-right: 0;
  padding-left: 0;
}
 
.col-xs-1-5 {
  width: 20%;
  float: left;
}
 
@media (min-width: 768px) {
  .col-sm-1-5 {
    width: 20%;
    float: left;
  }
}
 
@media (min-width: 992px) {
  .col-md-1-5 {
    width: 20%;
    float: left;
  }
}
 
@media (min-width: 1180px) {
  .col-lg-1-5 {
    width: 20%;
    float: left;
  }
}
