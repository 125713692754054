div.biostimul-ctools-modal-content {
  background: rgba(80,80,116, 0.95) no-repeat;
  border: none;
  margin: 0;
  width: auto;
  .biostimul-popup-header {
    box-sizing: border-box;
    border: none;
    background: none;
    padding: 40px 10% 15px 10%;
    text-align: center;
    .close {
      background: url(../images/modal_close.png) center no-repeat;
      cursor: pointer;
      height: 25px;
      position: absolute;
      right: 0;
      top: -30px;
      width: 25px;
    }
    .biostimul-popup-title {
      color: #fff;
      font-family: "Open Sans", sans-serif;
      font-size: 21px;
      white-space: normal;
    }
  }
}
#modalContent {
  left: 50% !important;
  transform: translate(-50%, 0);
  #modal-content {
    .webform-confirmation {
      color: #fff;
      font-size: 18px;
      padding: 0 30px;
      h2 {
        color: #fff;
        font-family: 'Open Sans', sans-serif;
        font-size: 36px;
        font-weight: 700;
      }
    }
    .links {
      display: none;
    }
    &.biostimul-popup-content {
      background: none !important;
      border: none;
      border-radius: 0;
      padding: 0;
      input[type=text],
      input[type=email] {
        background: #737390;
        border-radius: 19px;
        background-clip: padding-box;
        background-color: rgba(255, 255, 255, 0.2);
        border-color: rgba(255, 255, 255, 0.2);
        color: #fff;
        font-family: "Open Sans", sans-serif;
        font-size: 13px;
        padding: 0 20px;
        width: 100%;
        &::-moz-placeholder {
          color: #fff;
          opacity: 1;
        }
        &:-ms-input-placeholder {
          color: #fff;
        }
        &::-webkit-input-placeholder {
          color: #fff;
        }
      }
      textarea {
        background: #737390;
        border-radius: 19px;
        background-clip: padding-box;
        background-color: rgba(255, 255, 255, 0.2);
        border-color: rgba(255, 255, 255, 0.2);
        color: #fff;
        font-family: "Open Sans", sans-serif;
        font-size: 13px;
        height: 154px;
        padding: 5px 20px;
        resize: none;
        width: 100%;
        &::-moz-placeholder {
          color: #fff;
          opacity: 1;
        }
        &:-ms-input-placeholder {
          color: #fff;
        }
        &::-webkit-input-placeholder {
          color: #fff;
        }
      }
      input[type=submit] {
        background: #75b0a8;
        border: none;
        border-bottom: 4px solid #64968f;
        border-radius: 0;
        font-family: "Open Sans", sans-serif;
        font-size: 14px;
        height: 60px;
        padding: 4px 0 0 0;
        &:hover {
          opacity: 0.85;
        }
      }
      .form-item {
        margin: 26px 0;
        padding: 0 20px;
      }
      .form-actions {
        margin: 15px 0 60px 0;
        text-align: center;
      }
      .webform-client-form {
        width: 880px;
        .main-container {
          padding: 0 10%;
        }
        &.webform-client-form-114 {
          .main-container {
            max-width: 370px;
            margin: 0 auto;
            padding: 0;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  div.biostimul-ctools-modal-content {
    .biostimul-popup-header {
      padding: 20px 10% 5px 10%;
      width: 320px;
    }
  }
  #modalContent {
    #modal-content {
      &.biostimul-popup-content {
        .webform-client-form {
          width: 320px;
          &.webform-client-form-114 {
            .main-container {
              padding: 0 10%;
            }
          }
        }
        .form-item {
          margin: 10px 0;
          padding: 0;
        }
        .form-actions {
          margin: 10px 0 20px 0;
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  div.biostimul-ctools-modal-content {
    .biostimul-popup-header {
      width: 760px;
    }
  }
  #modalContent {
    #modal-content {
      &.biostimul-popup-content {
        .webform-client-form {
          width: 760px;
        }
      }
    }
  }
}

