.page-prices {
  .page-header {
    border: none;
    margin: 0;
  }
  h1.title {
    color: #505074;
    font-family: 'Open Sans', sans-serif;
    font-size: 25px;
    font-weight: 700;
    padding: 50px 0;
    text-align: center;
  }
}
.prices-pane {
  margin-bottom: 140px;
}
.view-id-healthy_service_price,
.view-id-prices {
  width: 100%;
  .views-row {
    margin-bottom: 8px;
  }
  .ui-accordion-header {
    background: #f6f6f6 url(../images/tab_noactive_icon.png) left 17px center no-repeat;
    border: none;
    border-radius: 0;
    border-bottom: 4px solid #d1d1d1;
    .ui-accordion-header-icon {
      background: url(../images/tab_arrows.png) 0 -13px no-repeat;
      height: 13px;
      left: auto;
      right: 19px;
      margin-top: -7px;
      width: 22px;  
    }
    a {
      color: #505074;
      font-family: 'Open Sans', sans-serif;
      font-size: 21px;
      font-weight: 700;
      .field-content {
        padding-left: 25px;
      }
    }
    &.ui-state-active {
      background: #f68e56 url(../images/tab_active_icon.png) left 17px center no-repeat;
      border-bottom: 4px solid #d17949;
      .ui-accordion-header-icon {
        background-position: 0 0;
      }
      a {
        color: #fff;
      }
    }
  }
  .ui-accordion-content {
    border: none;
    border-radius: 0;
    padding: 0 0 55px 0;
  }
  .paragraphs-item-service-list {
    padding-top: 20px;
    .field-name-field-type-service {
      color: #505074;
      font-family: 'Open Sans', sans-serif;
      font-size: 17px;
      font-weight: 700;
      float: left;
      padding: 10px 0;
      width: calc(100% - 120px);
    }
    .price_title {
      color: #505074;
      font-size: 14px;
      float: left;
      padding: 12px 0;
      text-align: center;
      width: 120px;
    }
  }
  .paragraphs-items-field-list-type-service {
    clear: both;
  }
  .field-name-field-list-type-service {
    > .field-items {
      > .field-item {
        display: inline-block;
        border-radius: 0 12px 12px 0;
        width: 100%;
        &.even {
          background: #eeeef2;
          .field-name-field-price-subservice {
            background-color: #505074;
          }
        }
        &.odd {
          background: #cde2df;
          .field-name-field-price-subservice {
            background-color: #75b0a8;
          }
        }
        .field-name-field-left-name-subservice {
          color: #303030;
          font-size: 14px;
          float: left;
          padding: 3px 15px;
          width: calc(100% - 120px);
        }
        .field-name-field-price-subservice {
          border-radius: 12px;
          color: #fff;
          float: left;
          font-size: 17px;
          font-weight: 700;
          height: 25px;
          line-height: 25px;
          text-align: center;
          width: 120px;
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1179px) {
  .view-id-healthy_service_price,
  .view-id-prices {
    padding-left: 20px;
    padding-right: 20px;
  }
}


@media (max-width: 767px) {
  .view-id-healthy_service_price,
  .view-id-prices {
    .ui-accordion-header {
      a {
        .field-content {
          max-width: 275px;
        }
      }
    }
    .paragraphs-item-service-list {
      .field-name-field-type-service {
        padding-left: 10px;
        padding-right: 10px;
        width: 100%;
      }
      .price_title {
        display: none;
      }
    }
    .field-name-field-list-type-service {
      > .field-items {
        > .field-item {
          border-radius: 0;
          padding-left: 10px;
          padding-right: 10px;
          padding-bottom: 5px;
          .field-name-field-left-name-subservice {
            padding-left: 0;
            width: 100%;
          }
        }
      }
    }
  }
}
