#logo {
  z-index: 10;
}
#main-wrapper {
  transition: all 0.3s ease-in-out;
}

#header-info {
  padding: 0 20px 0 0;
  .info-left {
    color: #9fa3bd;
    font-family: $normal;
    font-size: 13px;
    float: right;
    padding-right: 20px;
    padding-top: 20px;
  }
  .info-right {
    color: #fff;
    font-family: $normal;
    font-size: 25px;
    line-height: 28px;
    float: right;
    padding-top: 7px;
    .vodafone {
      background: url("../images/vodafone.png") no-repeat left center;
      padding-left: 23px;
    }
    .kyivstar {
      background: url("../images/kyivstar.png") no-repeat left center;
      padding-left: 23px;
    }
  }
}

#header-menu-container {
  clear: both;
  display: inline-block;
  float: right;
  transition: all 0.3s ease-in-out;
  .region-webform-popup-btns {
    position: absolute;
    top: 0;
    margin-left: 20px;
  }
  .block {
    float: left;
  }
  .block__content {
    a {
      color: #fff;
      outline: none;
      font-size: 14px;
      text-decoration: none;
      &:hover {
        opacity: 0.85;
      }
    }
  }
  .biostimul-popup-reception-block {
    .block__content {
      a {
        background: #f68e56;
        width: 186px;
      }
    }
  }
  .biostimul-popup-question-block {
    .block__content {
      a {
        background: #9fa4be;
        width: 186px;
      }
    }
  }
}

#header-menu-open {
  background: url(../images/header_menu_open.png) center no-repeat;
  cursor: pointer;
  display: none;
  height: 31px;
  padding: 5px;
  position: absolute;
  right: 15px;
  top: 65px;
  width: 38px;
  z-index: 3;
}
#header-menu-close {
  background: #8fcec5 url(../images/header_menu_close.png) right 20px center no-repeat;
  color: #505074;
  cursor: pointer;
  display: none;
  font-size: 18px;
  font-weight: 700;
  height: 61px;
  line-height: 61px;
  text-indent: 53px;
  width: 100%;
}

#header-social {
  position: absolute;
  top: 45px;
  display: inline-block;
  margin-left: 30px;
  .block__content {
    a {
      background-color: #9fa4be;
      border-radius: 3px;
      display: inline-block;
      font-size: 0;
      height: 30px;
      text-decoration: none;
      text-indent: -99999px;
      width: 30px;
      &.fb {
        background-image: url(../images/fb_icon.png);
        background-position: center;
        background-repeat: no-repeat;
      }
      &.vk {
        background-image: url(../images/vk_icon.png);
        background-position: center;
        background-repeat: no-repeat;
      }
      &.youtube {
        background-image: url(../images/youtube_icon.png);
        background-position: center;
        background-repeat: no-repeat;
      }
      &:hover {
        background-color: #8fcec5;
      }
    }
  }
}

@media (max-width: 1179px) {
  #header-social {
    top: 50px;
    right: 80px;
  }
  #main-wrapper {
    left: 0;
    position: relative;
  }
  #header-menu-open {
    display: block;
  }
  #header-menu-close {
    display: block;
  }
  #header-menu-container {
    background: #505074;
    bottom: 0;
    display: block;
    overflow: auto;
    position: fixed;
    right: -240px;
    top: 130px;
    width: 240px;
  }
  #page-wrapper {
    padding-top: 130px;
  }
  #header {
    height: 130px;
    left: 0;
    position: fixed;
    top: 0;
    z-index: 900;
    width: 100%;
    .region-webform-popup-btns {
      margin: 30px 0 0 0;
      position: relative;
      text-align: center;
      .block {
        float: none;
        margin-bottom: 14px;
      }
    }
  }
  body {
    &.menu-opened {
      #header-menu-open {
        display: none;
      }
      #header-menu-container {
        right: 0;
      }
      #main-wrapper {
        left: -240px;
      }
    }
    &.admin-menu {
      #header {
        top: 30px;
      }
      #header-menu-container {
        top: 160px;
      }
    }
  }
}
@media (max-width: 767px) {
  #header-social {
    top: 0;
    right: 10px;
  }
  #header {
    height: 150px;
    .info-left,
    .top-street {
      display: none;
    }
    #logo {
      height: auto;
      left: 20px;
      position: absolute;
      top: 70px;
      width: 203px;
    }
  }
  #header-info {
    padding: 12px 0 0 0;
    text-align: left;
    float: left;
    .info-right {
      padding-top: 0;
    }
    .float-right {
      padding-left: 20px;
      float: none;
    }
  }
  #header-menu-open {
    right: 5px;
  }
  body {
    #header-menu-container {
      top: 150px;
    }
    &.admin-menu {
      #header-menu-container {
        top: 180px;
      }
    }
  }
}