.what-we{
  padding-bottom: 85px;
  .pane-title{
    @include style-pane-title-blue();
  }
  ul{ 
    list-style: none;
    li{
      float: left;
      text-align: center;
      display: block;
      margin: 0 44px;
      width: calc(20% - 88px);
      color: #878787;
      img{
        display: block;
        margin: 0 auto;
        padding-bottom: 18px;
      }
      &:nth-child(1){
        margin: 0 44px 0 0;
        width: calc(20% - 44px);
      }
      &:last-child{
        width: calc(20% - 44px);
        margin: 0 0 0 44px;
      }
    }
  }
}
.clinick-in-number{
  @extend .what-we;
  margin-top: 60px;
  ul{
    li{
      width: calc(100% / 3 - 88px);
      &:nth-child(1){
        width: calc(100% / 3 - 88px);
        margin: 0 44px;
      }
      &:last-child{
        width: calc(100% / 3 - 88px);
        margin: 0 44px;
      }
    }
  }
}


.about-bluey-screen-2 {
  .pane-content{
    @include make-container();
    color: #fff;
    .body-blue{
      width: 65%;
      margin-top: 70px;
      margin-bottom: 70px;
      overflow: auto;
      max-height: 358px;
    }
  }
  background: url("../images/bg-about-blue.png") no-repeat center center;
  background-size: cover;
  height: 498px;
}




@media (max-width: 767px) {
  .page-about {
    .what-we,
    .screen-3 {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  .about-bluey-screen-2 {
    .pane-content {
      padding-left: 20px;
      padding-right: 20px;
      .body-blue {
        width: 100%;
      }
    }
  }
}


@media (min-width: 768px) and (max-width: 1179px) {
  .page-about {
    .what-we,
    .screen-3 {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  .about-bluey-screen-2 {
    .pane-content {
      padding-left: 20px;
      padding-right: 20px;
      .body-blue {
        width: 100%;
      }
    }
  }
}
