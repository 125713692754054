.services-price {
	padding: 60px 0px;
	.pane-title{
		color: #505074;
		font-size: 21px;
		font-weight: bold;
		display: block;
		text-align: center;
		padding-bottom: 40px;
	}
}