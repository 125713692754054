.method-top-wrapper {
  bottom: 0;
  left: 0;
  position: absolute;
  width: 100%;
  h1 {
    color: #fff;
    font-size: 55px;
    font-family: "ChunkFive Ex", Helvetica, Arial;
    margin-bottom: 30px;
  }
  .method-body-container {
    background: #75b0a8;
    font-size: 14px;
    color: #fff;
    padding: 20px;
  }
}
.pane-node-field-method-top-image {
  img {
    object-fit: cover;
    width: 100%;
  }
}


.pane-node-field-method-block-under-map {
  color: #878787;
  font-size: 14px;
  padding: 75px 0;
}
.pane-node-field-method-tab1-block1 {
  background: #f6f6f6;
  padding: 50px 0;
  .pane-title {
    color: #505074;
    font-family: 'Open Sans', sans-serif;
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 45px;
    text-align: center;
  }
}
.pane-node-field-method-tab3-block1 {
  background: #f6f6f6;
  padding: 50px 0;
  .pane-title {
    color: #505074;
    font-family: 'Open Sans', sans-serif;
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 45px;
    text-align: center;
  }
  .pane-content {
    color: #505074;
    font-size: 16px;
    .row {
      color: #878787;
    }
  }
}
.pane-node-field-method-procedure {
  padding: 65px 0 100px 0;
  .pane-title {
    color: #505074;
    font-family: 'Open Sans', sans-serif;
    font-size: 21px;
    font-weight: 700;
    margin-bottom: 28px;
    text-align: center;
  }
  .pane-content {
    color: #505074;
    font-size: 16px;
    .item {
      color: #fff;
      font-size: 14px;
      padding: 33px 50px 33px 40px;
      &.col-1 {
        background: #505074 url(../images/method_arrow_icon.png) right 15px center no-repeat;
      }
      &.col-2 {
        background: #75b0a8 url(../images/method_arrow_icon.png) right 15px center no-repeat;
      }
      &.col-3 {
        background: #f68e56;
      }
    }
  }
}

.pane-node-field-method-tab1-block3 {
  overflow: hidden;
  width: 100%;
  .pane-content {
    color: #fff;
    font-size: 14px;
    .field {
      position: relative;
      &:before {
        background: #505074;
        content: '';
        height: 100%;
        left: -1000px;
        position: absolute;
        top: 0;
        width: 1000px;
      }
      &:after {
        background: #75b0a8;
        content: '';
        height: 100%;
        right: -1000px;
        position: absolute;
        top: 0;
        width: 1000px;
      }
      .field-items {
        &:before {
          background: #505074;
          bottom: 0;
          content: '';
          height: 100%;
          position: absolute;
          left: 0;
          width: 50%;
          z-index: -1;
        }
        &:after {
          background: #75b0a8;
          bottom: 0;
          content: '';
          height: 100%;
          position: absolute;
          right: 0;
          width: 50%;
          z-index: -1;
        }
      }
    }
    .item {
      padding: 40px;
      position: relative;
      &.col-1 {
        background: #505074;
      }
      &.col-2 {
        background: #75b0a8;
      }
    }
    ul {
      li {
        list-style-image: url(../images/list-icon.png);
        margin-bottom: 15px;
      }
    }
  }
}

.pane-node-field-method-text-2column {
  color: #878787;
  font-size: 14px;
  padding: 55px 0 50px 0;
  .item {
    text-align: justify;
  }
}

.pane-node-field-method-tab1-block2 {
  padding: 10px 0 70px 0;
  .pane-title {
    color: #505074;
    font-family: 'Open Sans', sans-serif;
    font-size: 21px;
    font-weight: 700;
    margin-bottom: 28px;
    text-align: center;
  }
}


.pane-node-field-method-tab2-block1 {
  background: #505074;
  padding: 37px 0 60px 0;
  .pane-title {
    color: #fff;
    font-family: 'Open Sans', sans-serif;
    font-size: 21px;
    font-weight: 700;
    margin-bottom: 28px;
    text-align: center;
  }
  .pane-content {
    color: #fff;
    font-size: 14px;
    li {
      list-style: none;
      margin-bottom: 10px;
      &:before {
        content: "\2022";
        color: #75b0a8;
        font-size: 20px;
        padding-right: 10px;
      }
    }
  }
}


.pane-node-field-method-tab2-block2 {
  background: #f6f6f6;
  padding: 37px 0 60px 0;
  .pane-title {
    color: #505074;
    font-family: 'Open Sans', sans-serif;
    font-size: 21px;
    font-weight: 700;
    margin-bottom: 28px;
    text-align: center;
  }
  .pane-content {
    color: #505074;
    font-size: 15px;
    li {
      list-style: none;
      margin-bottom: 10px;
      &:before {
        content: "\2022";
        color: #f68e56;
        font-size: 20px;
        padding-right: 10px;
      }
    }
  }
}


.pane-node-field-method-tab2-block3 {
  color: #505074;
  padding-bottom: 50px;
  padding-top: 50px;
  .pane-title {
    color: #505074;
    font-family: 'Open Sans', sans-serif;
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 28px;
    text-align: center;
  }
  h3 {
    color: #505074;
    font-family: 'Open Sans', sans-serif;
    font-size: 21px;
    font-weight: 700;
    text-align: center;
  }
  .row1 {
    .item {
      color: #fff;
      font-size: 14px;
      padding: 40px 20px 20px 20px;
      .number {
        font-family: 'Open Sans', sans-serif;
        font-size: 60px;
        font-weight: 700;
        opacity: 0.15;
      }
      &.col-1 {
        background-color: #505074;
      }
      &.col-2 {
        background-color: #75b0a8;
      }
      &.col-3 {
        background-color: #f68e56;
      }
      &.col-4 {
        background-color: #9fa4be;
      }
    }
  }
  .row2 {
    margin: 0 auto;
    max-width: 768px;
    ul {
      li {
        list-style-image: url(../images/list-icon.png);
        line-height: 24px;
        margin-bottom: 12px;
      }
    }
  }
}


.pane-node-field-method-tab3-block2 {
  background: url(../images/method_bg1.jpg) center top no-repeat;
  background-size: cover;
  padding: 60px 0;
  .pane-content {
    color: #fff;
    font-size: 16px;
    h2 {
      font-family: 'Open Sans', sans-serif;
      font-size: 30px;
      font-weight: 700;
      margin-bottom: 30px;
    }
    h3 {
      font-family: 'Open Sans', sans-serif;
      font-size: 21px;
      font-weight: 700;
      margin: 25px 0 30px 0;
    }
    .row {
      max-width: 620px;
    }
    ul {
      li {
        list-style-image: url(../images/list_style2.png);
        line-height: 24px;
        margin-bottom: 12px;
      }
    }
  }
}

.pane-node-field-method-tab3-block3 {
  background: url(../images/method_bg2.jpg) center top no-repeat;
  background-size: cover;
  padding: 50px 0;
  .pane-title {
    color: #505074;
    font-family: 'Open Sans', sans-serif;
    font-size: 21px;
    font-weight: 700;
    margin-bottom: 50px;
    text-align: center;
  }
  .pane-content {
    color: #505074;
    font-size: 14px;
    ul {
      li {
        list-style-image: url(../images/list_style2.png);
        line-height: 24px;
        margin-bottom: 12px;
      }
    }
    .row1 {
      font-size: 16px;
    }
  }
}


@media (max-width: 767px) {
  .pane-node-field-method-tab3-block1 {
    padding-left: 20px;
    padding-right: 20px;
  }
  .pane-node-field-method-tab2-block1,
  .pane-node-field-method-tab2-block2 {
    padding-left: 20px;
    padding-right: 20px;
    .pane-content {
      ul {
        padding: 0;
      }
    }
  }
  .method-top-wrapper {
    h1 {
      font-size: 30px;
      padding: 0 20px;
    }
  }
}

@media (min-width: 768px) and (max-width: 1179px) {
  .pane-node-field-method-tab3-block3 {
    .row1 {
      margin: 0 auto;
      max-width: 480px;
    }
  }
  .pane-node-field-method-tab2-block3 {
    .row1 {
      .item {
        height: 240px;
      }
    }
  }
  .method-top-wrapper {
    h1 {
      font-size: 36px;
      padding: 0 20px;
    }
  }
}

.screen-4.method {
  background-color: #75b0a8;
  input[type=text],
  input[type=email] {
    background-color: rgba(255,255,255,0.22) !important;
  }
  input[type=submit] {
    background: #f68e56 !important;
    border-bottom-color: #d17949 !important;
  }
}

@media (max-width: 1179px) {
  .pane-node-field-method-tab3-block3,
  .pane-node-field-method-tab3-block2,
  .pane-node-field-method-text-2column,
  .pane-node-field-method-block-under-map {
    padding-left: 20px;
    padding-right: 20px;
  }
  .pane-node-field-method-tab1-block3 {
    .pane-content {
      .item {
        &.col-1 {
          &:before {
            background: #505074;
            content: '';
            height: 100%;
            left: -1000px;
            position: absolute;
            top: 0;
            width: 1000px;
            z-index: 2;
          }
          &:after {
            background: #505074;
            content: '';
            height: 100%;
            right: -1000px;
            position: absolute;
            top: 0;
            width: 1000px;
            z-index: 2;
          }
        }
        &.col-2 {
          &:before {
            background: #75b0a8;
            content: '';
            height: 100%;
            left: -1000px;
            position: absolute;
            top: 0;
            width: 1000px;
            z-index: 2;
          }
          &:after {
            background: #75b0a8;
            content: '';
            height: 100%;
            right: -1000px;
            position: absolute;
            top: 0;
            width: 1000px;
            z-index: 2;
          }
        }
      }
    }
  }
  .pane-node-field-method-procedure {
    .pane-content {
      .item {
        &.col-1,
        &.col-2 {
          background-image: none;
        }
      }
    }
  }
}

@media (min-width: 1180px) {
  .pane-node-field-method-tab3-block3 {
    .row1 {
      margin: 0 auto;
      max-width: 980px;
    }
    .row2 {
      margin: 0 auto;
      max-width: 980px;
    }
  }
  .pane-node-field-method-tab2-block3 {
    .row1 {
      .item {
        height: 280px;
      }
    }
  }
  .pane-node-field-method-procedure {
    .pane-content {
      .item {
        min-height: 262px;
      }
    }
  }
  .pane-node-field-method-text-2column {
    .item {
      &.col-1 {
        padding-right: 20px;
      }
      &.col-2 {
        padding-left: 20px;
      }
    }
  }
}