.node-type-disease .pane-node-field-slideshow,
.pane-node-field-slideshow.full-slideshow {
  .owl-theme {
    .owl-controls {
      margin: 0;
      .owl-prev {
        background: url(../images/owl_arrows.png) 0 0 no-repeat;
        border-radius: 0;
        font-size: 0;
        height: 58px;
        top: 50%;
        left: 0;
        margin: 0;
        opacity: 1;
        position: absolute;
        text-indent: -99999px;
        transform: translate(0, -50%);
        width: 41px;
      }
      .owl-next {
        background: url(../images/owl_arrows.png) 0 -58px no-repeat;
        border-radius: 0;
        font-size: 0;
        height: 58px;
        margin: 0;
        opacity: 1;
        position: absolute;
        right: 0;
        text-indent: -99999px;
        transform: translate(0, -50%);
        top: 50%;
        width: 41px;
      }
    }
  }
}

.owl-theme.owl-carousel-3-item {
  margin: 0 auto;
  position: relative;
  .owl-wrapper-outer {
    margin: 0 auto;
    img {
      display: block;
      height: auto;
      width: 100%;
    }
  }
  .owl-controls {
    .owl-prev {
      background: url(../images/owl_arrows.png) 0 0 no-repeat;
      border-radius: 0;
      font-size: 0;
      height: 58px;
      top: 50%;
      left: -18px;
      margin: 0;
      opacity: 1;
      position: absolute;
      text-indent: -99999px;
      transform: translate(0, -50%);
      width: 41px;
    }
    .owl-next {
      background: url(../images/owl_arrows.png) 0 -58px no-repeat;
      border-radius: 0;
      font-size: 0;
      height: 58px;
      margin: 0;
      opacity: 1;
      position: absolute;
      right: -18px;
      text-indent: -99999px;
      transform: translate(0, -50%);
      top: 50%;
      width: 41px;
    }
  }
}

@media (max-width: 767px) {
  .owl-theme.owl-carousel-3-item {
    margin: 0 auto;
    position: relative;
    .owl-wrapper-outer {
      margin: 0 auto;
      img {
        display: block;
        height: auto;
        width: 100%;
      }
    }
    .owl-controls {
      .owl-prev {
        left: 0;
      }
      .owl-next {
        right: 0;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1179px) {
  .owl-theme.owl-carousel-3-item {
    margin: 0 auto;
    width: 512px;
    .owl-wrapper-outer {
      .owl-item {
        width: 256px !important;
      }
      img {
        height: auto;
        width: 256px;
      }
    }
  }
}
@media (min-width: 1180px) {
  .owl-theme.owl-carousel-3-item {
    width: 1140px;
    .owl-wrapper-outer {
      .owl-item {
        width: 380px !important;
      }
    }
  }
}