// Mixins
// -----------------------------------------------------------------------------
@mixin colorNews($color){
  .read-more-news{
    background: $color;
  }
  .views-field-field-image{
    img{
      border-top: 4px solid $color;
    }
  }
}

@mixin make-container() {
  @include container-fixed();

  @media (min-width: $screen-sm-min) {
    width: $container-sm;
  }
  @media (min-width: $screen-md-min) {
    width: $container-md;
  }
  @media (min-width: $screen-lg-min) {
    width: $container-lg;
  }
}


@mixin style-pane-title-blue(){
  color: #4f4f73; // text color
  font-size: 21px;
  font-weight: bold;
  text-align: center;
  padding: 65px 0 60px 0;
}

