/***Conatacts***/
.kontakty{
	.pane-for-map{
		padding: 85px 0 68px 0;
		.pane-1{
			float: left;
			margin: 0 70px 0 0;
			.c-margin{
				margin: 0 0 26px 0;
			}
			.custom-row{
				.c-label{
					color: #878787;
					margin: 0 0 5px 0;
				}
				.c-text{
					margin: 0 0 3px 0;
					&:last-child{
						margin: 0;
					}
				}
				.orange{
					color: #f68e56;
					a{
						color: #f68e56;
					}
				}
			}	
		}
		.pane-webform-client-block-152{
			color: #878787;
			font-size: 13px;
			float: left;
			.pane-title{
				color: #4f4f73;
				font-size: 21px;
				font-weight: bold;
				margin: 0 0 19px 0;

			}
			.form-item{
				margin: 0 0 13px 0;
			}
			.webform-component--soobshchenie{
				float: right;
				margin: 0 0 0 22px;
			}
			.webform-component--imya,.webform-component--nomer-telefona,.webform-component-email{
				clear: left;
				float: left;
			}
			input[type="text"],input[type="email"]{
				max-width: 300px;
				height: 40px;
				background-color: #f5f5f5;
				-moz-box-shadow: inset 0 -4px 0 rgba(0,0,0,.15);
				-webkit-box-shadow: inset 0 -4px 0 rgba(0,0,0,.15);
				box-shadow: inset 0 -4px 0 rgba(0,0,0,.15);
				border-radius: 0px;
				border:none;
				padding: 0 0 0 25px;
			}
			textarea{
				max-width: 487px;
				height: 146px;
				background-color: #f5f5f5;
				-moz-box-shadow: inset 0 -4px 0 rgba(0,0,0,.15);
				-webkit-box-shadow: inset 0 -4px 0 rgba(0,0,0,.15);
				box-shadow: inset 0 -4px 0 rgba(0,0,0,.15);
				resize:none;
				border-radius: 0px;
				border:none;
				padding: 15px 0 0 25px;
				box-sizing:border-box;
			}
			.form-actions{
				clear: both;
				padding-top: 17px;
				input[type="submit"]{
					margin: 0;
					padding: 0;
					width: 173px;
					height: 65px;
					background-color: #75afa7;
					-moz-box-shadow: inset 0 -4px 0 rgba(0,0,0,.15);
					-webkit-box-shadow: inset 0 -4px 0 rgba(0,0,0,.15);
					box-shadow: inset 0 -4px 0 rgba(0,0,0,.15);
					color: #fff;
					font-size: 14px;
					font-weight: bold;
					border:none;
				}
			}
		}
	}
}

@media (max-width: 767px) {
	.kontakty {
		.pane-for-map {
			.pane-1 {
				padding: 0 20px;
				margin: 0;
				width: 100%;
			}
			.pane-webform-client-block-152 {
				margin-top: 30px;
				padding: 0 20px;
				width: 100%;
				input[type=text],
				input[type=email],
				textarea {
					max-width: 100%;
					width: 100%;
				}
				.form-item {
					width: 100%;
				}
				.webform-component--soobshchenie {
					margin: 0 0 13px 0;
					width: 100%;
				}
			}
		}
	}
}



@media (min-width: 768px) and (max-width: 1179px) {
	.kontakty {
		.pane-for-map {
			.pane-1 {
				padding: 0 20px;
				margin: 0;
				width: 100%;
				.left {
					display: inline-block;
					float: left;
					padding-right: 20px;
					width: 300px;
				}
				.right {
					display: inline-block;
					float: left;
					padding-left: 23px;
					width: calc(100% - 300px);
				}
			}
			.pane-webform-client-block-152 {
				margin-top: 30px;
				padding: 0 20px;
				width: 100%;
				.webform-component--soobshchenie {
					margin: 0 0 13px 0;
					width: 405px;
				}
			}
		}
	}
}




