#footer {
  margin: 0;
}
#footer-logo {
  padding: 23px 0 13px 0;
  text-align: center;
}

#footer-modal-btns-container {
  text-align: center;
  padding: 13px 0 19px 0;
  .block {
    display: inline-block;
  }
  .block__content {
    a {
      color: #fff;
      outline: none;
      font-size: 14px;
      text-decoration: none;
      &:hover {
        opacity: 0.85;
      }
    }
  }
  .biostimul-popup-reception-block {
    .block__content {
      a {
        background: #75b0a8;
      }
    }
  }
  .biostimul-popup-question-block {
    .block__content {
      a {
        background: #9fa4be;
      }
    }
  }
}

#main-menu-footer {
  text-align: justify;
  text-align-last: justify;
  width: 100%;
  > li {
    display: inline-block;
    float: none;
    > a {
      &:focus,
      &:hover {
        background: none;
      }
    }
  }
  li {
    text-align: left;
    text-align-last: left;
    &.open {
      > a {
        background: none;
      }
    }
    a {
      text-align: left;
      text-align-last: left;
    }
  }
}

#footer-copyright {
  color: #fff;
  font-size: 13px;
  padding: 19px 0 25px 0;
  text-align: center;
  a {
    color: #fff;
  }
}

@media (max-width: 769px) {
  #footer-modal-btns-container {
    .block {
      display: block;
    }
  }
}
@media (max-width: 1179px) {
  #footer {
    padding: 0 20px;
  }
  #main-menu-footer {
    display: none;
  }
}
